import * as React from 'react';

import { AppBar, Box, Toolbar, Typography } from '@mui/material';

import Nickname from "./Nickname";
import MenuDrawer from "./MenuDrawer";
//import PlayCircleIcon from '@mui/icons-material/PlayCircle';

export default function Header(props) {

	const {username, setUsername} = props;

	return (
		<Box
		//sx={{ flexGrow: 1 }}
		>
			<AppBar position="static">
				<Toolbar>
					<MenuDrawer />
					<Typography variant="h6" component="div"
						sx={{ flexGrow: 1 }}
					>
						scrumpoker works
          			</Typography>
					<Nickname username={username} setUsername={setUsername} />
				</Toolbar>
			</AppBar>
		</Box>
	);
}