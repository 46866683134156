import React, { } from "react";

import { Chip, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';


/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'

export default function EstChip(props) {

	const { val, sendBid } = props;

	const theme = useTheme();

	return (
		<Typography variant='body2' component="span"
			sx={{ color: theme.palette.primary.light }}
		>
			<Chip
				label={val}
				onClick={(val) => sendBid(val)}
				color='primary'
				//variant='outlined'
				css={css`
					width: 3.0rem;
					height: 3.0rem;
					font-size: 0.8rem;
					font-weight: bold;
				`}
				// color: ${theme.palette.primary.light};
			/>

		</Typography>
	);
}
