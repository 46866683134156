import React, { } from "react";

import { Card, CardContent, Divider, Typography } from '@mui/material';

import { useTheme } from '@mui/material/styles';
import HideSourceIcon from '@mui/icons-material/HideSource';
import PendingIcon from '@mui/icons-material/Pending';

import styled from '@emotion/styled';

import BidCard from "./BidCard";

export const CardInner = styled(Card)`
		padding: 0.2rem 0px;
		display: flex;
		justify-content: center;
		//background: red;
`;

export default function Bid(props) {

	const { bid, hidden } = props;

	const theme = useTheme();

	return (
		<BidCard>
			<CardContent>
				<CardInner>
					<Typography variant='body2'
						sx={{ color: theme.palette.primary.light }}
					>
						{bid && bid.nick}
					</Typography>
				</CardInner>
				<Divider />
				<CardInner>
					<Typography variant='h5'>
						{!hidden && bid.amount}
						{!hidden && !bid.amount && '-'}
						{hidden && bid.amount && <HideSourceIcon /> }	
						{hidden && !bid.amount && <PendingIcon />}
					</Typography>
				</CardInner>
			</CardContent>
		</BidCard>
	);
}
