import React, { } from "react";

import { Box, Card, FormControl, TextField } from '@mui/material';

//import { useTheme } from '@mui/material/styles';

//import withStyles from '@mui/styles/withStyles';

import styled from '@emotion/styled';

export const CardBox = styled(Card)`
		padding: '0.2em 0px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
`;


export default function Nickname(props) {

	const { username, setUsername } = props;

	let error = (!username || username === '');
	
	const handleChange = (event) => {
		 // console.log('handleChange username:' + event.target.value);
		localStorage.setItem('username', event.target.value);
		setUsername(event.target.value);
		error = (!username || username === '');
	};
	


	function handleSubmit(event) {
		//localStorage.setItem('username', username);
		event.preventDefault();
	}

	// todo enhance: input with useRef
	// https://blog.logrocket.com/usestate-vs-useref/

	return (
		<>
			<Box
				component="form"
				onSubmit={handleSubmit}
				noValidate
				required
				autoComplete="off">
				<FormControl > {/* error */}
					{/* <InputLabel InputLabelProps={{ shrink: true }} htmlFor="username">nickname</InputLabel> */}
					<TextField
						id="username"
						//label="nick"
						helperText={error && "enter nickname"}
						variant="standard"
						value={username}
						onChange={handleChange}
						sx={{ maxWidth: '6em', marginLeft: '0.5em' }}
						error={error}
						autoFocus={error}
					/>
				</FormControl>
			</Box>
		</>
	);
}
