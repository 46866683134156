import React, { useState, createContext } from 'react';

export const ErrorContext = createContext();

export const ErrorProvider = props => {

	const [errormsg, setErrormsg] = useState("");

	return <ErrorContext.Provider
		value={[errormsg, setErrormsg]}>
		{props.children}
	</ErrorContext.Provider>;
};

export const useError = () => React.useContext(ErrorContext)



/*// see https://felixgerschau.com/react-hooks-settimeout/
	const timerRef = useRef(null);

	//const timer = setTimeout(() => console.log('Initial timeout!'), 3000);
	

	const timedErrormsg = (props) => {
		setErrormsg(props);
		timerRef.current = setTimeout(() => setErrormsg(""), 5000);
		// timerRef.current = setTimeout(() => setErrormsg(""), 5000);
	}

	useEffect(() => {
		// Clear the interval when the component unmounts
		return () => clearTimeout(timerRef.current);
	}, [])
*/