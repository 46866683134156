import React, { useEffect } from "react";
import { Box, Paper, Typography } from '@mui/material';


/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'



export default function QRCode(props) {

	const { showQR, setShowQR, table_id } = props;

	const src = window.location.protocol.concat("//").concat(window.location.hostname).concat(window.location.port ? ':' + window.location.port : '').concat("/table/" + table_id + "/qrcode");

	const handleClick = () => {
		setShowQR('none');
	}

	return (
		<Box css={css`
		        	// display: block;
        			justify-content: center;
				`}
			component="div"
			sx={{ display: showQR }}
		>

			<p />

			<Box css={css`
		        	display: flex;
        			justify-content: center;
				`}
				component="div"
			>


				<p />

				<img
					src={src}
					onClick={() => handleClick()}
				/>

			</Box >

		</Box>
	);
}

