import React, { } from 'react';

import Networking from "./lib/Networking";

export default function AppCreateID() {
	
		
	console.log('This is AppCreateID.js');
	

	// optimize: two new ids are generated - due to calling on mounts/unmount?
	
	// const [newid, setNewid] = React.useState(null);

	let newid;
	let url = window.location.protocol.concat("//").concat(window.location.hostname).concat(':').concat(window.location.port);
	// let url = window.location.href;

	const createTableID = () => {
		//loadingCountInc();
		Networking.exec({
			endpoint: client => client.apis.TableResource.createTableID,
			// attributes: { table_id: '1', nick: nick, bid: amount, cmd: cmd },
			// data: { requestBody: {} },
			success: result => {
				console.log("createTableID");
				console.log(result.body);
				newid = result.body.id;
				if (newid) {
					console.log('newid: ' + newid);
					url = url.concat(url.endsWith('/') ? '' : '/').concat('?table=' + newid);
					localStorage.setItem('table_id', newid);
					window.location.assign(url);
				} else {
					console.log('invalid createTableID: ' + newid)
				}
			},
			failure: error => {
				console.log("failed to retrieve createTableID: " + error);
				//loadingCountReset();
			}
		})
	}

	createTableID(null, null, null); 

	return (

		<div id='App'>
			Preparing new table id...
			
			{/* 
			{(!newid || newid.length === 0) && 'Error creating a new table id!'}
			<p />
			<Link href="{url}">
				reload
			</Link>
			&nbsp;with new id
			
			*/}
			
		</div>

	);
}

