import React, { useEffect } from "react";

import BidListVertical from "./BidListVertical";
import EstChip from "./EstChip";

import { IconButton, FormControlLabel, Stack, Switch, Tooltip, Typography } from '@mui/material';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import GroupRemoveIcon from '@mui/icons-material/GroupRemove';

import { useData } from "./ctx/DataContext";
// import { useSse } from "./ctx/SseContext";


//import { useMethod } from "./ctx/MethodContext";


export default function TableSP(props) {

	const { username } = props;

	const [ data, setData, callDataTable, table_id ] = useData();
	// const { sseInboundData } = useSse();

	//const [, loadingCountInc, loadingCountReset] = useLoading();


	const sendBid = (bid) => {
		callDataTable(username, bid);
	}

/*	useEffect(() => {
		console.debug("TableSp.useEffect[sseInboundData]");
		console.debug(sseInboundData);
		
		setData(sseInboundData);
				
	}, [sseInboundData]);*/

	const estValues1 = (['?', '0', '1', '2', '3', '5']);
	const estValues2 = (['8', '13', '20', '40', '100',]);
	const estTshirts1 = (['S', 'M', 'L', 'XL']);
	const estTshirts2 = (['?', 'BIG',]);

	return (
		<>


				<p />
				{(data && data.method == 'tshirt') ?
					<>
						<Stack direction="row" spacing={2} justifyContent="center">
							{estTshirts1.map((sp, index) => {
								return (
									<EstChip key={index} val={sp} sendBid={() => sendBid(sp)}
									/>
								)
							})}
						</Stack>
						<p />
						<Stack direction="row" spacing={2} justifyContent="center">
							{estTshirts2.map((sp, index) => {
								return (
									<EstChip key={index} val={sp} sendBid={() => sendBid(sp)}
									/>
								)
							})}
						</Stack>
					</>

					/* default method == 'sp'*/
					:
					<>
						<Stack direction="row" spacing={2} justifyContent="center">
							{estValues1.map((sp, index) => {
								return (
									<EstChip key={index} val={sp} sendBid={() => sendBid(sp)}
									/>
								)
							})}
						</Stack>
						<p />
						<Stack direction="row" spacing={2} justifyContent="center">
							{estValues2.map((sp, index) => {
								return (
									<EstChip key={index} val={sp} sendBid={() => sendBid(sp)}
									/>
								)
							})}
						</Stack>
					</>
				}

				<p />

				{data && 

					<Stack direction="row" spacing={7} justifyContent="space-around">

						<Tooltip title='clear the room from all members' arrow>
							<IconButton onClick={() => {
								callDataTable(null, null, null, 'clear');
							}}
							>
								<GroupRemoveIcon color='disabled' />
							</IconButton>
						</Tooltip>

						<FormControlLabel
							control={
								<Tooltip title='hide or show bids' arrow>
									<Switch color="primary"
										checked={!data.hidden}
										onChange={() => callDataTable(null, null, null, 'showhide')}
										inputProps={{ 'aria-label': 'controlled' }}
									>
									</Switch>
								</Tooltip>
							}
							label=""
						/>

						<Tooltip title='reset all bid values' arrow>
							<IconButton onClick={() => {
								callDataTable(null, null, null, 'reset');
							}}
							>
								<CancelPresentationIcon color='disabled' /
								></IconButton>
						</Tooltip>

					</Stack>
				}

				<p />

				{(!data || !data.bids || data.bids.length === 0) &&
					<Typography align='center' variant='h6'>
						<p>
							no bids
					</p>
					</Typography>
				}
				{
					data && data.bids &&
					<BidListVertical username={username} />

				}


		</>
	);
}
