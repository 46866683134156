import React, { } from "react";

import { Card } from '@mui/material';
import styled from '@emotion/styled';

export const BidCardStyled = styled(Card)`
		width: 6.5rem;
		height: 5.5rem;
`;

export default function BidCard(props) {

	return (
		<BidCardStyled>
			{props.children}
		</BidCardStyled>
	);
}
