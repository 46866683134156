import React, { useState, useEffect } from "react";

import { Divider, SwipeableDrawer, Link, List, ListItem, ListItemText, IconButton, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
//import LinkIcon from '@mui/icons-material/Link';

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'

import Networking from "./lib/Networking";

import { useThemeContext } from "./ctx/ThemeContext";
import { useData } from "./ctx/DataContext";

export default function MenuDrawer(props) {

	const [data, setData, callDataTable, table_id] = useData();
	const [state, setState] = useState(false);

	const toggleDrawer = (open) => (event) => {
		if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
			return;
		}
		setState(open);
	};

	const [colorMode, theme, mode] = useThemeContext();

	const toggleDarkLight = () => {
		colorMode.toggleColorMode();
		localStorage.setItem('colorMode', mode === 'dark' ? 'light' : 'dark');
	}

	//useEffect(() => {
	//}, []);

	const goWebsite = () => {
		window.location.assign('https://blog.scrumpoker-works.com/');
	}

	const goPrivacyPolicy = () => {
		window.location.assign('https://blog.scrumpoker-works.com/privacy-policy/');
	}

	const goImprint = () => {
		window.location.assign('https://blog.scrumpoker-works.com/imprint/');
	}

	const handleChangeRadioMethod = (e) => {
		console.log('Menu handleChangeRadioMethod: ' + e.target.value);
		data.method = e.target.value;
		callDataTable(null, null, data.method, null);
		localStorage.setItem('method', data.method);

		setState(false); // close drawer
		// update will follow via sse 
	};

	/*	useEffect(() => {
			//console.log('Menu: useEffect render,  method:' + method);
			return () => {
				// cleanup
			};
		}, [method])*/

	return (
		<div>
			<div>
				<IconButton
					edge="start"
					color="inherit"
					aria-label="open drawer"
					onClick={toggleDrawer(true)}
					sx={{ mr: 2 }}
				//sx={{ mr: 2, display: { xs: 'block', sm: 'none', }, }}
				>
					<MenuIcon />
				</IconButton>
			</div>
			<SwipeableDrawer
				anchor='left'
				open={state}
				onClose={toggleDrawer(false)}
				onOpen={toggleDrawer(true)}
			>
				<div
					css={css`
					//width: 15rem;
				`}
					role="presentation"
				//onClick={toggleDrawer(false)}
				//onKeyDown={toggleDrawer(false)}
				>

					<List>
						<ListItem >
							<IconButton
								onClick={toggleDrawer(false)}
							//sx={{ mb: 2 }}
							>
								<CloseIcon />
							</IconButton>
						</ListItem>

						<ListItem>
							{theme.palette.mode} mode
      						<IconButton sx={{ ml: 1 }} onClick={toggleDarkLight} color="inherit">
								{theme.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
							</IconButton>
						</ListItem>

						{/*
						<Divider />

						<ListItem>
							<FormControl>
								<FormLabel id="demo-radio-buttons-group-label">method</FormLabel>
								<RadioGroup
									aria-labelledby="demo-radio-buttons-group-label"
									name="radio-buttons-group"
									defaultValue={method}
									value={method}
									onChange={handleChangeMethod}
								>
									<FormControlLabel value="sp" control={<Radio />} label="storypoints" />
									<FormControlLabel value="tshirt" control={<Radio />} label="t-shirts" />
								</RadioGroup>
							</FormControl>
						</ListItem>
*/}

						<Divider />

						<ListItem>
							{data &&
								<RadioGroup>
									<p>
										<Radio
											checked={data.method === 'sp'}
											onChange={handleChangeRadioMethod}
											value='sp'
											name="radio-buttons"
											inputProps={{ 'aria-label': 'A' }}
										/> storypoints
								</p>
									<p>
										<Radio
											checked={data.method === 'tshirt'}
											onChange={handleChangeRadioMethod}
											value='tshirt'
											name="radio-buttons"
											inputProps={{ 'aria-label': 'B' }}
										/> t-shirts
							</p>
								</RadioGroup>
							}
						</ListItem>

						<Divider />

						<ListItem button onClick={goWebsite}>
							<ListItemText primary='website' />
						</ListItem>

						<ListItem button onClick={goPrivacyPolicy}>
							<ListItemText primary='privacy policy' />
						</ListItem>

						<ListItem button onClick={goImprint}>
							<ListItemText primary='imprint' />
						</ListItem>

					</List>

				</div>

			</SwipeableDrawer>
		</div>
	);
}
