import React, { useRef, useEffect, useState, createContext } from 'react';

import Networking from "../lib/Networking";

import { useError } from "./ErrorContext";

export const DataContext = createContext();

// context for table data ()which is communicated via REST and SSE)
export const DataProvider = props => {

	const { table_id } = props;

	const [data, setData] = useState(null);

	const [errormsg, setErrormsg] = useError();

	// see https://felixgerschau.com/react-hooks-settimeout/
	const watchdogTimerRef = useRef(null);
	const wdTimeout = 4000;
	let lastactive = new Date(); // to disable watchdog after certain time of inactivity
	const maxinactive = 5; // in minutes

	const setLastactive = () => {
		lastactive = new Date();
	}

	// initialize DataProvider
	useEffect(() => {
		console.debug('DataProvider: useEffect[]');
		callDataTable();

		return () => {
			// any cleanup?
		};
	}, [])

	// sse - see also code in bottom section
	useEffect(() => {
		console.debug("SseContext.useEffect []");
		const es = connectNewEventSource();
		startWatchdogTimer();
		return () => {
			es.current.close();
			// Clear the interval when the component unmounts
			clearTimeout(watchdogTimerRef.current);
		}

	}, [])


	const callDataTable = (nick, amount, method, cmd) => {
		callDataTableInternal(nick, amount, method, cmd);
		setLastactive();
	}

	// used by watchdogSSE internally
	const callDataTableInternal = (nick, amount, method, cmd) => {
		//loadingCountInc();

		// conditional client side initializations
		if (amount && !nick) {
			nick = 'unkown';
		}

		// network call
		Networking.exec({
			endpoint: client => client.apis.TableResource.table,
			attributes: { table_id: table_id, nick: nick, bid: amount, method: method, cmd: cmd },
			// data: { requestBody: {} },
			success: result => {
				//console.debug("DataProvider callTable data: ");
				//console.debug(result.body);
				setData(result.body);
				// init estimation method from localstorage on startup
				if (result && (!result.body.method || result.body.method === "")) {
					const initMethod = localStorage.getItem('method') ? localStorage.getItem('method') : 'sp';
					callDataTable(null, null, initMethod, null);
				}
				//loadingCountReset();
			},
			failure: error => {
				console.warn("DataProvider failed to retrieve table data: " + error);
				setErrormsg("DataProvider failed to retrieve table data: " + error);
				//loadingCountReset();
			}
		})
	}


	// SSE context - useEffect see upper part
	const eventSourceRef = useRef(null);


	const startWatchdogTimer = (props) => {
		console.debug("startWatchdogTimer")
		watchdogTimerRef.current = setTimeout(() => watchdogSSE(), wdTimeout);
	}

	const watchdogSSE = () => {
		//console.debug("watchdogSSE " + new Date());
		
		// console.debug("eventSourceRef.current ->");
		// console.debug(eventSourceRef.current);
		//console.debug(eventSourceRef.current && ("eventSourceRef.current.readyState: " + eventSourceRef.current.readyState));
		
		// 0 — connecting
		// 1 — open
		// 2 — closed
		if (eventSourceRef.current == null || eventSourceRef.current.readyState == EventSource.CLOSED) {
			console.debug('eventSourceRef.current is null or EventSource.CLOSED');
			connectNewEventSource();
		}
		// EventSource.CONNECTING) 
		// seen on chkunking proxies
		// seen on initial connection situations

		// TODO To TRY
		// - implement a probe - SSE confirmed - send a probe and receive a sse as ACKnowledge
		// - maybe try a full reload then?
		// - do long polling fallback only when needed

		callDataTableInternal();

		const diffTime = Math.abs(new Date() - lastactive);
		const diffMinutes = Math.floor(diffTime / (1000 * 60)); // minutes: (1000 * 60)
		// console.debug('diffMinutes: ' + diffMinutes);

		if (diffMinutes < maxinactive) {
			watchdogTimerRef.current = setTimeout(watchdogSSE, wdTimeout);
		} else {
			console.info("watchdog stopped after " + maxinactive + " minutes of inactivity. Reload SPA if you like to restart watchdog.");
			clearTimeout(watchdogTimerRef.current);
		}
		//clearTimeout(watchdogTimerRef.current);
	}

	const receiveEventSource = (dataES) => {
		console.debug("SseContext receiveEventSource dataES ====================================");
		console.debug(dataES);
		setLastactive();

		let parsedData = JSON.parse(dataES);

		setData(parsedData);
	};

	const connectNewEventSource = () => {
		console.log("connectNewEventSource");
		if (typeof (EventSource) == 'undefined') {
			console.error('Error: Server Sent Events are not supported in your browser!');
			setErrormsg('Error: Server Sent Events are not supported in your browser!');
		}
		const BaseURL = window.location.protocol.concat("//").concat(window.location.hostname).concat((window.location.port === '3000') || (window.location.port === '8080') ? ':8080' : '');
		try {
			eventSourceRef.current = new EventSource(`${BaseURL}/table-sse/${table_id}`);
		} catch (e) {
			console.error('error on new EventSource');
			console.error(e);
			setErrormsg("error on new EventSource - see console for details");
		}
		eventSourceRef.current.onmessage = (em) => {
			console.debug(em);
			receiveEventSource(em.data);
		}
		eventSourceRef.current.onopen = (e) => { } // console.log("eventSource.onopen"); console.log(e); }
		eventSourceRef.current.onclose = (e) => {
			//console.log("eventSource.onclose");
			console.debug(e);
		}
		eventSourceRef.current.onerror = (e) => {
			if (e.readyState == EventSource.CLOSED) {
				console.log('eventSource is closed');
			}
			else {
				console.debug(e);
				// setErrormsg("error on eventSource." + JSON.stringify(e));
			}
		};

		return eventSourceRef;
	}

	return <DataContext.Provider value={[data, setData, callDataTable, table_id]}>{props.children}{/* data: {JSON.stringify(data)}*/}</DataContext.Provider>;
};

export const useData = () => React.useContext(DataContext)

