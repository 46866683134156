import SwaggerClient from "swagger-client";

//import { useError } from "../ctx/ErrorContext";

export default class Networking {

	//const [errormsg, setErrormsg] = useError();

//	constructor(props) {
//		//super(props);
//		this.state = {
//			errormsg: useError(),
//		};
//	}

	static client = new SwaggerClient({

		// see also package.json proxy
		url: window.location.protocol.concat("//").concat(window.location.hostname).concat(':').concat(window.location.port).concat('/q/openapi'),


		// alternative to configure on dev with port 3000 use quarkus rest calls to 8080
		//url: window.location.protocol.concat("//").concat(window.location.hostname).concat((window.location.port === '3000') || (window.location.port === '8080') ? ':8080/q/openapi' : '/q/openapi'),

		// FALLBACK IDEA - use ENV variable to query for DEV or PROD profile - https://quarkus.io/guides/config-reference

		// OPTIONAL: require all responses in JSON format
		requestInterceptor: req => {
			if (req.body && !req.headers["Content-Type"]) {
				req.headers["Content-Type"] = "application/json"
			}
		}

	});

	static getDefaultSecurity = () => {
		let token = localStorage.getItem("id_token");
		return token == null ? {} : {
			securities: {
				authorized: {
					bearerAuth: {
						value: token
					}
				}
			}
		}
	}

	static exec = ({
		endpoint,
		attributes,
		data,
		success, // = () => { console.log('attributes' + attributes); },
		failure = res => { console.log('failed on api call: ' + res); },
		security = this.getDefaultSecurity()
	}) => {
		this.client.then(
			client => endpoint(client)(attributes, { ...data, ...security }),
			reason => {
				failure(reason);
				console.error('failed to load the spec: ' + reason);
//				this.setState(state => ({
//					errormsg:
//						state.errormsg = 'failed to load the spec: ' + reason,
//				}));
			}
		).then(success, failure)
			.catch(err => {
				console.log('catch: ');
				console.log(err)
			})
	}
}
