import React, { useEffect } from "react";
import { Box, Paper, Typography } from '@mui/material';

import Header from "./Header";
import TableSP from "./TableSP";
import Footer from "./Footer";

import { useError } from "./ctx/ErrorContext";
import ErrorDisplay from "./ctx/ErrorDisplay";
import { useSettings } from "./ctx/SettingsContext";

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'


export default function Scrumpoker(props) {

	const [username, setUsername] = React.useState(localStorage.getItem('username'));

	const [errormsg, setErrormsg] = useError();

	return (
		<Box css={css`
		        	display: flex;
        			//flex-flow: column nowrap;
        			justify-content: center;
        			//align-items: center;
				`}>

			<Paper elevation={1}
				css={css`
						//max-width: 500px;

					`}>

				<Header username={username} setUsername={setUsername} />

				{/* SEO tribute */}
				<p />
				<Typography component="h1"
					variant="body2"
					align="center"
				>
					<i>easy estimation game for agile working
					<br/>a free online tool</i>
				</Typography>

				<ErrorDisplay errormsg={errormsg} setErrormsg={setErrormsg} />

				<TableSP username={username} />

				<p />

				<Footer />

			</Paper>
		</Box>

	);
}

