import React, { } from "react";

import Bid from "./Bid";

import { Box, Stack, Tooltip } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';

import styled from '@emotion/styled';

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'

import { useThemeContext } from "./ctx/ThemeContext";

import { useData } from "./ctx/DataContext";

export default function BidListVertical(props) {

	const [colorMode, theme, mode] = useThemeContext();

	const [ data, setData, callDataTable, table_id ] = useData();

	const MiddleTable = styled(Box)`
            //position: relative;
            //height: 6.5rem;
 			border-radius: 6.5rem;
            width: 6.5rem;
			background-color: ${theme.palette.action.disabledBackground};
			// center refresh icon
			// https://www.freecodecamp.org/news/how-to-center-anything-with-css-align-a-div-text-and-more/
			position: relative;
`;

	const { username } = props;

	// move own bid to first position
	if (data.bids && data.bids.length > 1) {
		const myBid = data.bids.find(x => x.nick === username);
		if (myBid) {
			const foundIdx = data.bids.findIndex(el => el === myBid);
			data.bids.splice(foundIdx, 1);
			data.bids.unshift(myBid);
		}
	}

	var bidStack1 = [];
	let i;
	for (i = 0; i < data.bids.length / 2; i = i + 1) {
		bidStack1.push(<Bid
			key={i}
			bid={data.bids[i]}
			hidden={data.hidden && (username !== data.bids[i].nick // show own bid
				|| data.bids.length === 1)} // when only one bid is made (yours?), hide own bid
		/>);
		bidStack1.push(<p key={data.bids.length + i} />);
	}

	var bidStack2 = [];
	for (; i < data.bids.length; i = i + 1) {
		bidStack2.push(<Bid
			key={i}
			bid={data.bids[i]}
			hidden={data.hidden && username !== data.bids[i].nick}
		/>);
		bidStack2.push(<p key={data.bids.length + i} />);
	}

	const handleRefresh = () => {
		window.location.assign(window.location.href);
	}

	return (
		<Stack direction="row" spacing={1} justifyContent="center">
			<Stack direction="column" spacing={1} justifyContent="center">
				{bidStack1}
			</Stack>

			<MiddleTable onClick={handleRefresh}>
				<Tooltip title='refresh to see actual bids' arrow>
					<RefreshIcon
						color='disabled'
						css={css`
							// text-align: center;
	  						position: absolute;
	  						top: 50%;
	  						left: 50%;
	  						transform: translate(-50%, -50%);
					`}
						onClick={handleRefresh}
					/>
				</Tooltip>
			</MiddleTable>

			<Stack direction="column" spacing={1} justifyContent="center">
				{bidStack2}
			</Stack>
		</Stack>
	);
}
