import React, { useContext } from 'react';
import ReactGA from 'react-ga';

import AppHelmet from './AppHelmet';
import { HelmetProvider } from 'react-helmet-async';
import AppCreateID from "./AppCreateID";
import Scrumpoker from "./Scrumpoker";

import CssBaseline from '@mui/material/CssBaseline';
//import { customTheme } from './mui/MuiSetup';
import { StyledEngineProvider } from '@mui/material/styles';

import { ThemeContextProvider } from "./ctx/ThemeContext";

import { ErrorProvider } from "./ctx/ErrorContext";
import { SettingsProvider } from "./ctx/SettingsContext";
import { ConnStatProvider } from "./ctx/ConnStatContext";
import { DataProvider } from "./ctx/DataContext";


function App() {

	const queryParams = new URLSearchParams(window.location.search);
	let table_id = queryParams.get('table');
	console.log('App queryParams table_id: ' + table_id);

	// https://javascript.plainenglish.io/how-to-setup-and-add-google-analytics-to-your-react-app-fd361f47ac7b
	// const TRACKING_ID = "UA-233489766-1"; //  old format from google TRACKING_ID
	const TRACKING_ID = "321655541"; // YOUR_TRACKING_ID
	ReactGA.initialize(TRACKING_ID);

	if (!table_id || table_id === null) {
		table_id = localStorage.getItem('table_id');
		console.log('table_id from local storage: ' + table_id);
		if (table_id) {
			let url = window.location.protocol.concat("//").concat(window.location.hostname).concat(':').concat(window.location.port);
			url = url.concat('/?table=' + table_id);
			window.location.assign(url);
		}
	} else {
		localStorage.setItem('table_id', table_id);
	}

	return (
		<HelmetProvider>
			<div className="App">
				<AppHelmet />
				<StyledEngineProvider injectFirst>
					<ThemeContextProvider>
						<CssBaseline />

						{!table_id && <AppCreateID />}
						{table_id &&
							<>

								<ErrorProvider>
									<SettingsProvider>
										<ConnStatProvider>
											<DataProvider table_id={table_id}>
												<Scrumpoker />
											</DataProvider>
										</ConnStatProvider>
									</SettingsProvider>
								</ErrorProvider>

							</>
						}

					</ThemeContextProvider>
				</StyledEngineProvider>
			</div>
		</HelmetProvider>
	);
}


export default App;
