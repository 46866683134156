import React, { useRef, useEffect, useState, createContext } from 'react';

import Networking from "../lib/Networking";

export const SettingsContext = createContext();

export const SettingsProvider = props => {

	//const { table_id } = props;

	const [method, setMethod] = useState(localStorage.getItem('method') ? localStorage.getItem('method') : 'sp');

	// how to put callTable with TableSP.js into a lib file?
/*	const callTable = (nick, amount, method, cmd) => {
		Networking.exec({
			endpoint: client => client.apis.TableResource.table,
			attributes: { table_id: table_id, method: method },
			success: result => {
				console.log("MethodProvider callTable data: ");
				console.log(result.body);
			},
			failure: error => {
				console.log("failed to retrieve table data: " + error);
			}
		})
	}*/

/*	useEffect(() => {
		console.debug('SettingsContext: useEffect[method] with method: ' + method);
		return () => {
			// cleanup
		};
	}, [method])*/

	// initialize
/*	useEffect(() => {
		console.log('MethodContext: useEffect with method: ' + method);
		// query and set local storage
		// TODO
		return () => {
			// any cleanup?
		};
	}, [])*/


	return <SettingsContext.Provider value={[method, setMethod]}>{props.children}{/* "method:" {method}*/}</SettingsContext.Provider>;
};

export const useSettings = () => React.useContext(SettingsContext)

