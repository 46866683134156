import React, { useState, createContext } from 'react';

export const ConnStatContext = createContext();

export const ConnStatProvider = props => {

	const [connstat, setConnstat] = useState("");

	return <ConnStatContext.Provider
		value={[connstat, setConnstat]}>
		{props.children}
	</ConnStatContext.Provider>;
};

export const useConnStat = () => React.useContext(ConnStatContext)
