import React from "react";
import { Helmet } from 'react-helmet-async';


export default function AppHelmet() {

	return (
		<Helmet >
			<meta charSet="utf-8" />
			<title>scrumpoker works</title>
			<meta name="description" content="scrumpoker for quick and easy use - free and online" />
			<link // Roboto font: https://mui.com/material-ui/react-typography/#general
				rel="stylesheet"
				href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
			/>
			<link // Font icons
				rel="stylesheet"
				href="https://fonts.googleapis.com/icon?family=Material+Icons"
			/>
			<link rel="canonical" href="https://scrumpoker-works.com/" />
		</Helmet >
	);

}