import React, { useRef, useEffect, useState, createContext } from 'react';

import { Alert, Button } from '@mui/material';

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'

export default function ErrorDisplay(props) {

	const { errormsg, setErrormsg } = props;

	const handleRefresh = () => {
		window.location.assign(window.location.href);
	}
	
	return (
		<>
			<p />
			<div css={css`
		        	display: flex;
        			justify-content: center;
				`}>


				{errormsg &&
					<Alert
						severity="warning"
						action={
							<Button color="inherit" size="small"
							onClick={handleRefresh} >
								reload
    					</Button>
						}
					>
						{errormsg}
					</Alert>
				}
			</div>
			<p />
		</>
	)
}
