import React, { useState, useRef } from "react";

import { Box, IconButton, Link, Stack, Tooltip, Typography } from '@mui/material';
import ShareIcon from '@mui/icons-material/Share';
import FiberNewIcon from '@mui/icons-material/FiberNew';
import QrCode2Icon from '@mui/icons-material/QrCode2';
//import AutorenewIcon from '@mui/icons-material/Autorenew';

import { useError } from "./ctx/ErrorContext";
import { useData } from "./ctx/DataContext";

import QRCode from "./QRCode";


export default function Footer() {

	const [errormsg, setErrormsg] = useError();
	const [data, setData, callDataTable, table_id] = useData();
	const [showQR, setShowQR] = useState('none'); // none, flex
	const footerBottomRef = useRef(null);

	const handleNewRoom = () => {
		console.log('new room clicked');
		localStorage.removeItem('table_id');
		window.location.assign(window.location.protocol.concat("//").concat(window.location.hostname).concat(":").concat(window.location.port));
	}

	const shareData = {
		title: 'scrumpoker.works',
		text: 'Join our table at scrumpoker.works',
		//url: 'https://developer.mozilla.org' 
		url: window.location.href
	}

	//const resultPara = document.querySelector('.result');

	// Share must be triggered by "user activation"
	/*	btn.addEventListener('click', async () => {
	
		});*/

	// https://stackoverflow.com/questions/64422691/copy-text-of-a-specific-tag-to-clipboard-using-javascript
	function copy(input) {
		if (navigator.clipboard) {
			navigator.clipboard.writeText(input).then(() => {
				console.log('Copied to clipboard successfully.');
			}, (err) => {
				console.log('Failed to copy the text to clipboard.', err);
				setErrormsg('Failed to copy the text to clipboard.' + JSON.stringify(err));
			});
		} else if (window.clipboardData) {
			window.clipboardData.setData("Text", input);
		}
	}

	// https://developer.mozilla.org/en-US/docs/Web/API/Web_Share_API
	// https://www.telerik.com/blogs/using-web-share-api-react
	// https://en.wikipedia.org/wiki/Share_icon
	const handleShare = () => {
		if (typeof navigator.canShare === "function") {
			if (navigator.canShare(shareData)) {
				try {
					//await 
					navigator.share(shareData)
					//resultPara.textContent = 'MDN shared successfully'
				} catch (err) {
					console.log(err);
					setErrormsg(err);
				}
			}
			else {
				console.log('navigator can not share - copy url manually');
				copy(window.location.href);
				setErrormsg('URL to share copied to your clipboard');
			}
		}
		else {
			// https://developer.mozilla.org/en-US/docs/Web/API/Navigator/canShare
			console.log('navigator.canShare not implemented by browser or not activated in context');
			copy(window.location.href);
			setErrormsg('URL to share copied to your clipboard');
			// TODO give message directly at button
		}
	}

	const handleShowQR = () => {
		// block or none
		if (showQR === 'block') {
			setShowQR('none');
		} else {
			setShowQR('block');
			footerBottomRef.current?.scrollIntoView();
		}
	}

	return (
		<Box sx={{ flexGrow: 1 }}>
			<p />
			<Stack direction="row" spacing={12} justifyContent="space-around">
				<Stack direction="row" spacing={2} justifyContent="space-around">
					<Tooltip title='create a new table' arrow>
						<IconButton onClick={() => handleNewRoom()}>
							<FiberNewIcon />
						</IconButton>
					</Tooltip>
					<Box sx={{ display: 'flex', alignItems: 'center' }}>
						<Tooltip title='blog for scrumpoker works' arrow>
							<Typography component="" variant="body2">
								<Link href="https://blog.scrumpoker-works.com/" target="_blank" rel="noopener" >blog</Link>
							</Typography>
						</Tooltip>
					</Box>
				</Stack>
				<Stack direction="row" spacing={2} justifyContent="space-around">
					{/* https://smartdevpreneur.com/how-to-align-mui-typography-vertically-center-right-left/#Vertically_Center_MUI_Typography */}

					<Box sx={{ display: 'flex', alignItems: 'center' }}>
						<Tooltip title='ID and link of your scrumpoker table' arrow>
							<Typography component="" variant="body2">
								<Link href={window.location.href}>{table_id}</Link>
							</Typography>
						</Tooltip>
					</Box>
					<Tooltip title='share url for new members to join' arrow>
						<IconButton onClick={() => handleShare()}>
							<ShareIcon />
						</IconButton>
					</Tooltip>
					<Box sx={{ display: 'flex', alignItems: 'center' }}>
						<Tooltip title='show QR code' arrow>
							<IconButton onClick={() => handleShowQR()}>
								<QrCode2Icon />
							</IconButton>
						</Tooltip>
					</Box>
				</Stack>
			</Stack>
			<QRCode showQR={showQR} setShowQR={setShowQR} table_id={table_id} />
			<div ref={footerBottomRef} />
			<p />
		</Box >
	);
}